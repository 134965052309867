<template>
  <div>
    <kn-form-subtitle title="Agregar grupo" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Nombre de grupo:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="group.nombre_grupo"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-doc-item
        class="py-0"
        idInput="brandImg"
        label="Imagen del grupo:"
        @saveFile="saveImage"
        @clearFile="clearImage"
      />
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Sucursal:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="group.id_sucursal"
            dense
            outlined
            :rules="[rules.required]"
            :items="sucursals"
            item-value="id"
            item-text="nombre_sucursal"
            
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Salón de clases:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="group.id_salon_clases"
            dense
            outlined
            :rules="[rules.required]"
            :items="classrooms"
            item-value="id"
            item-text="nombre_salon"
          />
        </v-col>
      </v-row><v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Ciclo escolar:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-select
            v-model="group.id_ciclo_escolar"
            dense
            outlined
            :rules="[rules.required]"
            :items="schoolCycle"
            item-value="id"
            item-text="dato"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de grupos"
      divider-position="bottom"
    />
    <v-list two-line>
      <v-list-item-group>
        <template v-for="(item, index) in activeGroups">
          <v-list-item :key="item.nombre_salon">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === group.id"
                  class="mb-0 pb-0"
                  v-model="group.nombre_grupo"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre_grupo"
                />
                <kn-doc-item
                  v-if="toUpdate && item.id === group.id"
                  class="py-0"
                  :showLabel="false"
                  @saveFile="saveImage"
                  @clearFile="clearImage"
                />
                <v-img
                  v-else
                  :src="item.imagen_grupo"
                  height="100px"
                  max-width="100px"
                />
                <v-select
                  v-if="toUpdate && item.id === group.id"
                  class="mb-0 pb-0"
                  v-model="group.id_sucursal"
                  dense
                  outlined
                  :items="sucursals"
                  item-value="id"
                  item-text="nombre_sucursal"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Sucursal: ${item.surcursal.nombre_sucursal}`"
                />
                <v-select
                  v-if="toUpdate && item.id === group.id"
                  class="mb-0 pb-0"
                  v-model="group.id_salon_clases"
                  dense
                  outlined
                  :items="classrooms"
                  item-value="id"
                  item-text="nombre_salon"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Salón: ${item.salon_clases.nombre_salon}`"
                />
                <v-select
                  v-if="toUpdate && item.id === group.id"
                  class="mb-0 pb-0"
                  v-model="group.id_ciclo_escolar"
                  dense
                  outlined
                  :items="schoolCycle"
                  item-value="id"
                  item-text="dato"
                />
                <v-list-item-subtitle
                  v-else
                  v-text="`Ciclo escolar: ${item.ciclo_escolar.dato}`"
                />
                <v-list-item-subtitle
                  v-if="!toUpdate"
                  v-text="`Número de alumnos: ${item.alumnos.length ? item.alumnos.length : 'Ninguno'}`"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === group.id"
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="clearInline"
                      title="Cancelar"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="save"
                      title="Guardar cambios"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="edit(item)"
                      title="Editar"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      @click="deleteInline(item.id)"
                      title="Eliminar"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activeGroups.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnDocItem: () => import('../forms/KnDocItem.vue')
  },
  mixins: [
    validationFormMixin,
    fileUtilsMixin,
    apiMixin
  ],
  data() {
    return {
      errors: [],
      valid: true,
      group: {
        id: 'NA', // No asignado
        nombre_grupo: null,
        id_sucursal: null,
        id_salon_clases: null,
        id_ciclo_escolar: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      groups: [],
      classrooms: [],
      sucursals: [],
      schoolCycle: [],
      toUpdate: false,
      imageFile: null
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activeGroups() {
      return this.groups.filter(group => group.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.group)
    }
  },
  async created() {
    this.group.id_autor = this.userData.id
    this.group.id_institucion_educativa = this.institutionId
    this.groups = await this.fetchResultsByEI('personas', 'grupo', this.institutionId) //this.fetchGroupByEI(this.institutionId)
    this.sucursals = await this.fetchResultsByEI('personas', 'sucursal', this.institutionId) //this.fetchSucursalsByEI(this.institutionId)
    this.classrooms = await this.fetchResultsByEI('personas', 'salon-clases', this.institutionId) //this.fetchClassroomByEI(this.institutionId)
    this.schoolCycle = await this.fetchResultsByEI('personas', 'ciclo-escolar', this.institutionId) //this.fetchSchoolCycle()
  },
  methods: {
    async createGroup() {
      try {
        const response = await this.postObj('/personas/crear-grupo', {
          nombre_grupo: this.group.nombre_grupo,
          id_salon_clases: this.group.id_salon_clases,
          id_sucursal: this.group.id_sucursal,
          id_ciclo_escolar: this.group.id_ciclo_escolar,
          id_institucion_educativa: this.group.id_institucion_educativa
        })
        if (response.error) {
          this.errors.push(response.error)
        } else {
          this.assignImageToGroup(response.id_objeto)
        }
      } catch (error) {
        console.error('Error al intentar crear el grupo', error)
        this.errors.push('Error al intentar crear el grupo')
      }
    },
    async updateGroup() {
      try {
        const response = await this.postObj('/personas/update-grupo', {
          id: this.group.id,
          nombre_grupo: this.group.nombre_grupo,
          id_sucursal: this.group.id_sucursal,
          id_salon_clases: this.group.id_salon_clases,
          id_ciclo_escolar: this.group.id_ciclo_escolar,
          id_institucion_educativa: this.institutionId
        })
        if (response.error) {
          this.errors.push(response.error)
        } else {
          this.assignImageToGroup(this.group.id)
        }
      } catch (error) {
        console.error('Error al intentar actualizar el grupo', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteGroup(groupId) {
      try {
        const response = await this.postObj('/personas/delete-grupo', {
          id_grupo: groupId
        })
        if (response.error) {
          this.errors.push(response.data)
        }
      } catch (error) {
        console.error('Error al intentar eliminar el grupo', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updateGroup()
        this.toUpdate = false
      } else {
        await this.createGroup()
        this.clear()
      }
      this.groups = await this.fetchResultsByEI('personas', 'grupo', this.institutionId)
      this.clear()
    },
    clearInline() {
      this.group = {
        id: 'NA', // No asignado
        nombre_grupo: null,
        id_sucursal: null,
        id_salon_clases: null,
        id_ciclo_escolar: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.clearImage()
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clear() {
      this.group = {
        id: 'NA', // No asignado
        nombre_grupo: null,
        id_sucursal: null,
        id_salon_clases: null,
        id_ciclo_escolar: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.clearImage()
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.group = {...item}
      this.group.id_sucursal = item.surcursal.id
      this.group.id_salon_clases = item.salon_clases.id
      this.group.id_ciclo_escolar = item.ciclo_escolar.id
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deleteGroup(itemId)
      this.groups = await this.fetchResultsByEI('personas', 'grupo', this.institutionId)
      this.clear()
    },
    async assignImageToGroup(groupId) {
      try {
        if (this.imageFile !== null) {
          const responseUpload = await this.uploadFile(this.imageFile)
          if (responseUpload.mensaje.includes('Archivo guardado con éxito')) {
            const urlImage = responseUpload.archivos[0].url_archivo
            const responseAddImage = await this.postObj('/personas/update-imagen-grupo', {
              url_imagen: urlImage,
              id_grupo: groupId
            })
            if (responseAddImage.error) {
              this.errors.push(responseAddImage.error)
            }
          }
        }
      } catch (error) {
        console.error('Ocurrió un error al intentar agregar imagen a grupo', error);
      }
    },
    saveImage(obj) {
      this.imageFile = obj
    },
    clearImage() {
      this.imageFile = null
    }
  },
}
</script>

<style>

</style>